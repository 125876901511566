import { mapData } from "@/stuff/DataMapping";
import { ResponseSetStatus } from './Enums';
import { IModule, Module } from './Module';
import { ISupplierResponseSet, SupplierResponseSet } from './SupplierResponseSet';

export interface IModuleWithResponses {
    supplierID: number;
    module: IModule;
    responseSets: Array<ISupplierResponseSet>;
}


export class ModuleWithResponses {
    
    constructor(data?: IModuleWithResponses) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IModuleWithResponses) {
        mapData(data, { 
            root: () => this,
            responseSets: () => new SupplierResponseSet(),
            module: () => this.module
        });
    }

    supplierID: number = 0;
    module: Module = new Module();
    responseSets: Array<SupplierResponseSet> = [];

    get hasACurrentInPlayResponse(): boolean {
        return this.responseSets.some(r => r.isCurrent && r.isInPlay);
    }

    get hasACurrentApprovedResponse(): boolean {

//         if(this.responseSets.some(r => 
//             r.isCurrent && (
//                 (r.isLegacy && r.legacyProgressID === 0) 
//                 || r.status === ResponseSetStatus.Accepted)
//             )) {
//                 for(const rs of this.responseSets.filter(r => 
//                     r.isCurrent && (
//                         (r.isLegacy && r.legacyProgressID === 0) 
//                         || r.status === ResponseSetStatus.Accepted)
//                     )) {
// console.log(`@@@@@@@ id=${rs.id} status=${ResponseSetStatus[rs.status]} isCurrent=${rs.isCurrent} legacyProgressID=${rs.legacyProgressID}`);
//                 }
//             }

        return this.responseSets.some(r => 
            r.isCurrent && (
                (r.isLegacy && r.legacyProgressID === 0) 
                || r.status === ResponseSetStatus.Accepted)
            );
    }

    get treeKey(): string {
        return ModuleWithResponses.keyFromId(this.module.id);
    }

    static keyFromId(id: number): string {
        return `mod:${id}`;
    }
}