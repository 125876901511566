import { mapData } from "@/stuff/DataMapping";

export interface ISupplierTabCounts {
    userCount: number;
    buyerCount: number;
    regionCount: number;
    contactCount: number;
    historyCount: number;
    buyerCommsCount: number;
    supplierCommsCount: number;
    sommsCount: number;
    paymentCount: number;
    moduleCount: number;
    certificateCount: number;
    documentCount: number;
}

export class SupplierTabCounts {

    constructor(data?: ISupplierTabCounts) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISupplierTabCounts) {
        mapData(data, { root: () => this });
    }

    userCount: number = 0;
    buyerCount: number = 0;
    regionCount: number = 0;
    contactCount: number = 0;
    historyCount: number = 0;
    buyerCommsCount: number = 0;
    supplierCommsCount: number = 0;
    commsCount: number = 0;
    paymentCount: number = 0;
    moduleCount: number = 0;
    certificateCount: number = 0;
    documentCount: number = 0;
}
