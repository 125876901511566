
    import Vue from "vue";
    import Component from "vue-class-component";
    import SlideUpDown from "vue-slide-up-down";
    import MultiDocument from "@/components/MultiDocument.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import { Validations } from "vuelidate-property-decorators";
    import * as toastr from "toastr";
    import ApiButton from "@/components/ApiButton.vue";
    import LegacyDocumentList from "@/components/LegacyDocumentList.vue";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import eventBus from "@/stuff/EventBus";
    import fileDownload from "@/stuff/FileDownload";
    import store from "@/store/store";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { IModuleWithResponses, ModuleWithResponses } from "@/model/ModuleWithResponses";   
    import { ISupplierResponseSet, SupplierResponseSet } from "@/model/SupplierResponseSet";
    import { Document as ModelDocument } from "@/model/Document";
    import { ResponseSetStatus, UserRole, LookupGroup } from "@/model/Enums";
    import { IModule } from "@/model/Module";
    import { Supplier } from "@/model/Supplier";
    import { ISite } from "@/model/Site";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import { ModuleSearchParameters } from "@/model/ModuleSearchParameters";
    import { SiteSearchParameters } from "@/model/SiteSearchParameters";
    import { Certificate, certificateTypeOptions } from "@/model/Certificate";
    import { ChaseHistory } from "@/model/ChaseHistory";
    import { required } from "vuelidate/lib/validators";

    @Component({
        components: { ApiButton, SlideUpDown, MultiDocument, LegacyDocumentList, DatePicker }
    })
    export default class SupplierModulesTab extends Vue {

        async mounted() {
            eventBus.$on("server-data-changed", (what: string, id: number, userID: string) => {
                console.log(`#-#-# SIGNALR - SupplierModules.vue - what=${what} id=${id} userID=${userID}`);
            });

            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;

            // module search parameters
            this.searchParameters.isForBuyerZone = this.isBuyerZone;
            this.searchParameters.buyerID = this.isBuyerZone
                ? store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID
                : 0;
            this.searchParameters.supplierID = this.supplierID;

            // fire off all the loads in parallel
            await Promise.all([
                store.dispatch("loadUserList"),
                this.loadCqmsUsers(),
                this.loadAllModules(),
                this.loadSchemeNames(),
                this.loadCdmCategories(),
                this.loadSiteList(),
                this.loadLegacyProgresses(),
                this.loadBuyers(),
                this.load(),
            ]);
            this.certificateTypeOptions = certificateTypeOptions("Choose...");
        }

        @Prop({ required: true })
        private supplierID!: number;

        @Prop({ required: true })
        private supplier!: Supplier;

        private isWaiting: boolean = true;

        private isBuyerZone: boolean = false; // set in mounted

        private searchParameters = new ModuleSearchParameters();

        private get userList(): Array<LookupItem> { return this.$store.state.userList; }
        private cqmsUserList: Array<ILookupItem> = [];
        private cqmsUserOptions: Array<ILookupItem> = [];
        private buyerList: Array<ILookupItem> = [];

        private allModules: Array<IModule> = [];  // just a lookup
        private modulesWithResponses: Array<ModuleWithResponses> = [];

        private schemeNameList: Array<ILookupItem> = [];
        private schemeNameOptions: Array<ILookupItem> = [];

        private siteList: Array<ISite> = [];
        private siteOptions: Array<ILookupItem> = [];

        private legacyProgressList: Array<ILookupItem> = [];
        private legacyProgressOptions: Array<ILookupItem> = [];

        private cdmCategoryList: Array<ILookupItem> = [];

        private certificateTypeOptions: Array<LookupItem> = [];

        responseSet = new SupplierResponseSet();
        private certificate = new Certificate();
        private chaseHistory = new ChaseHistory();
        private shouldDisableResponseSetIsLegacy: boolean = false;

        private canDeleteResponseSet(responseSet: SupplierResponseSet): boolean {
            return !this.isBuyerZone && (responseSet.canDelete || store.state.signedInUser?.role == 1);
        }

        private canCreateResponseSet(moduleWithResponses: ModuleWithResponses): boolean {
            return !this.isBuyerZone && !moduleWithResponses.hasACurrentInPlayResponse;
        }

        private moduleIconVariant(module: ModuleWithResponses): string {
            if(module.hasACurrentApprovedResponse) return "success";
            if(module.hasACurrentInPlayResponse) return "warning";
            return "danger";
        }

        private moduleIconTitle(module: ModuleWithResponses): string {
            if(module.hasACurrentApprovedResponse) return "Has a current, approved response set";
            if(module.hasACurrentInPlayResponse) return "Has a response set in progress"; 
            return "No current or in-progress response set";
        }

        private moduleIconGlyphCss(module: ModuleWithResponses): string {
            if(module.hasACurrentApprovedResponse) return "fas fa-check";
            if(module.hasACurrentInPlayResponse) return "fas fa-tasks";
            return "fas fa-times";
        }

        private responseSetIconVariant(responseSet: SupplierResponseSet): string {
            if (!responseSet.isCurrent) return "secondary"
            if (responseSet.status === ResponseSetStatus.Legacy) return "success";
            if (responseSet.status === ResponseSetStatus.LegacyInProgress) return "warning";
            if (responseSet.status === ResponseSetStatus.AwaitingCompletion) return "info";
            if (responseSet.status === ResponseSetStatus.Submitted) return "warning";
            if (responseSet.status === ResponseSetStatus.Rejected) return "danger";
            if (responseSet.status === ResponseSetStatus.Accepted) return "success";
            return "dark";
        }

        private responseSetStatusText(responseSet: SupplierResponseSet): string {
            if (!responseSet.isCurrent) return "Expired"
            if (responseSet.status === ResponseSetStatus.Legacy) return "Legacy";
            if (responseSet.status === ResponseSetStatus.AwaitingCompletion) return "Awaiting completion";
            if (responseSet.status === ResponseSetStatus.Submitted) return "Submitted";
            if (responseSet.status === ResponseSetStatus.Rejected) return "Rejected";
            if (responseSet.status === ResponseSetStatus.Accepted) return "Accepted";
            return "- - -";
        }

        private responseSetIconGlyphCss(responseSet: SupplierResponseSet): string {
            if (!responseSet.isCurrent) return "fas fa-archive";
            if (responseSet.status === ResponseSetStatus.Legacy) return "fas fa-file";
            if (responseSet.status === ResponseSetStatus.LegacyInProgress) return "fas fa-tasks";
            if (responseSet.status === ResponseSetStatus.AwaitingCompletion) return "fas fa-tasks";
            if (responseSet.status === ResponseSetStatus.Submitted) return "fas fa-paper-plane";
            if (responseSet.status === ResponseSetStatus.Rejected) return "fas fa-times";
            if (responseSet.status === ResponseSetStatus.Accepted) return "fas fa-check";
            return "fas fa-question-circle";
        }

        private progressBadgeVariant(responseSet: ISupplierResponseSet): string {
            return responseSet.questionCount >= responseSet.responseCount ? "success" : "danger";
        }

        private canIssueCertificate(responseSet: ISupplierResponseSet): boolean {
            return !utils.isEmptyId(responseSet.schemeNameID) && [ResponseSetStatus.Accepted, ResponseSetStatus.Legacy].includes(responseSet.status);
        }

        private issueCertificateTooltip(responseSet: ISupplierResponseSet): string {
            if ([ResponseSetStatus.Accepted, ResponseSetStatus.Legacy].indexOf(responseSet.status) == -1) return "Module has not been accepted";
            if (utils.isEmptyId(responseSet.schemeNameID)) return "Scheme is missing";
            return "Issue Certificate";
        }

        //
        // -- watchers
        //

        @Watch("supplier")
        onSupplierChanged() {
            this.load();
        }

        private hasResponses(moduleWithResponses: IModuleWithResponses): boolean {
            const count = (moduleWithResponses && moduleWithResponses.responseSets) ? moduleWithResponses.responseSets.length : 0;
            return count > 0;
        }

        //
        // -- history dialogue
        //

        private async showChaseHistory(responseSet: ISupplierResponseSet) {
            const response = await apiClient.get(`/api/supplierResponse/load?id=${responseSet.id}`);
            this.responseSet.update(response);
            this.$v.$reset();
            this.$bvModal.show("chaseHistoryDialogue");
        }

        private closeChaseHistory() {
            this.$bvModal.hide("chaseHistoryDialogue");
        }

        private addChaseHistory() {
            this.$v.$reset();
            utils.resetObject(this.chaseHistory);
            this.chaseHistory.responseSetID = this.responseSet.id;
            this.$bvModal.hide("chaseHistoryDialogue");
            this.$bvModal.show("addChaseHistoryDialogue");
        }

        private closeNewChaseHistory() {
            this.$bvModal.hide("addChaseHistoryDialogue");
            this.$bvModal.show("chaseHistoryDialogue");
        }

        private async saveChaseHistory() {
            this.$v.$touch();
            if (this.$v.chaseHistory.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            
            const responseData: { message: string } = await apiClient.post("api/supplierResponse/saveChaseHistory", this.chaseHistory);
            if (responseData.message === "ok") {
                toastr.success("Chase history saved");
                this.$bvModal.hide("addChaseHistoryDialogue");
                this.showChaseHistory(this.responseSet);
                this.load();
            }
            else {
                toastr.error("Failed to save chase history");
            }
        }

        private showHistory(responseSet: ISupplierResponseSet) {
            this.responseSet.update(responseSet);
            this.$v.$reset();
            this.$bvModal.show("historyDialogue");
        }

        private closeHistory() {
            this.$bvModal.hide("historyDialogue");
        }

        private get isAnyChaseHistory(): boolean {
            if(!this.responseSet.chaseHistory) false;
            return this.responseSet.chaseHistory.length > 0;
        }

        private chaseHistoryBadge(responseSet?: ISupplierResponseSet): string {
            const rs = !responseSet ? this.responseSet : responseSet;
            if(!rs) return "-";
            if(!rs.chaseHistory) return "-";
            return rs.chaseHistory.length.toString();
        }

        private get isAnyHistory(): boolean {
            if(!this.responseSet.history) false;
            return this.responseSet.history.length > 0;
        }

        private historyBadge(responseSet?: ISupplierResponseSet): string {
            const rs = !responseSet ? this.responseSet : responseSet;
            if(!rs) return "-";
            if(!rs.history) return "-";
            return rs.history.length.toString();
        }

        //
        // -- documents dialogues
        //

        private get documentListTitle() {
            const module = this.allModules.find(m => m.id === this.responseSet.moduleID);
            const moduleDesc = module == null ? ("id=" + this.responseSet.moduleID) : module.description;
            return "Documents for " + moduleDesc;
        }

        private showDocuments(responseSet: ISupplierResponseSet) {
            this.responseSet.update(responseSet);
            this.$v.$reset();
            const dialogueName = this.isBuyerZone ? "documentsViewDialogue" : "documentsDialogue";
            this.$bvModal.show(dialogueName);
        }

        private closeDocuments() {
            const dialogueName = this.isBuyerZone ? "documentsViewDialogue" : "documentsDialogue";
            this.$bvModal.hide(dialogueName);
        }

        private get areAnyDocuments(): boolean {
            if(!this.responseSet.documents) false;
            return this.responseSet.documents.length > 0;
        }

        private documentsBadge(responseSet?: ISupplierResponseSet): string {
            const rs = !responseSet ? this.responseSet : responseSet;
            if(!rs) return "-";
            if(!rs.documents) return "-";
            return rs.documents.length.toString();
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }

        //
        // -- certificate dialogue
        //

        private moduleForResponseSet(responseSetID: number): IModule|null {
            for(const module of this.modulesWithResponses) {
                for(const rs of module.responseSets) {
                    if(rs.id === responseSetID) return module.module;
                }
            }
            return null;
        }

        private get certificateTitle(): string {
            const module = this.moduleForResponseSet(this.responseSet.id);
            return "New certificate for " + (module ? module.description : "(error!)");
        }

        private createCertificate(responseSet: ISupplierResponseSet) {
            const module = this.moduleForResponseSet(responseSet.id);
            this.responseSet.update(responseSet);
            utils.resetObject(this.certificate);
            this.certificate.supplierID = this.supplierID;
            this.certificate.supplierName = responseSet.companyLegalName;
            this.certificate.supplierAddress = this.supplier.addressNonHtml;
            this.certificate.supplierResponseSetID = responseSet.id;
            this.certificate.schemeName = utils.lookupDescription(responseSet.schemeNameID, this.schemeNameList);
            this.certificate.trade = responseSet.trade;
            this.certificate.cdmCategory = module ? utils.lookupDescription(module.cdmCategoryID, this.cdmCategoryList) : "- - -"
            this.certificate.expiry = responseSet.expires ?? new Date(utils.emptyDateValue);
            this.certificate.approval = responseSet.approved ?? new Date(utils.emptyDateValue);
            this.certificate.numberOfPersonnel = responseSet.numberOfEmployees;
            // issued date is the created date and is set on server when saving
            this.$v.$reset();
            this.$bvModal.show("certificateDialogue");
        }

        private async saveCertificate(shouldNotify: boolean, event?: Event) {
            this.$v.$touch();
            if (this.$v.certificate.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const apiMethod = shouldNotify ? "saveAndNotify" : "save";
            const responseData: { newCount: number; newID: number } = await apiClient.post(`api/certificate/${apiMethod}`, this.certificate);
            this.certificate.id = responseData.newID;
            toastr.success(this.certificate.description, "Created a new certificate");
            this.$emit("certificateCountChanged", responseData.newCount); // update tab count badge in parent
            this.$bvModal.hide("certificateDialogue");
        }

        private closeCertificateDialogue() {
            this.$bvModal.hide("certificateDialogue");
        }

        //
        // -- edit dialogue 
        //

        private editNewResponseSet(moduleWithResponses: IModuleWithResponses) {
            utils.resetObject(this.responseSet);
            this.$v.$reset();
            this.shouldDisableResponseSetIsLegacy = moduleWithResponses.module.isDocument;
            this.responseSet.supplierID = this.supplierID;
            this.responseSet.moduleID = moduleWithResponses.module.id;
            this.responseSet.status = moduleWithResponses.module.isDocument 
                ? ResponseSetStatus.LegacyInProgress
                : ResponseSetStatus.AwaitingCompletion;
            this.responseSet.activeFrom = utils.today();
            this.responseSet.expires = new Date(utils.emptyDateValue);
            this.responseSet.companyLegalName = this.supplier.companyLegalName;
            this.responseSet.numberOfEmployees = this.supplier.numberOfEmployees;
            this.responseSet.numberOfSubcontractors = this.supplier.numberOfSubcontractors;
            this.responseSet.history.length = 0;
            this.$bvModal.show("editResponseSetDialogue");
        }

        public get responseSetIsLegacy(): boolean {
            return this.responseSet.status === ResponseSetStatus.Legacy || this.responseSet.status === ResponseSetStatus.LegacyInProgress;
        }

        public set responseSetIsLegacy(isLegacy: boolean) {
            this.responseSet.status = isLegacy 
                ? ResponseSetStatus.LegacyInProgress 
                : ResponseSetStatus.AwaitingCompletion;
        }

        private responseSetEdit(responseSet: ISupplierResponseSet) {
            this.responseSet.update(responseSet);
            this.shouldDisableResponseSetIsLegacy = true; // ???
            this.$v.$reset();
            this.$bvModal.show("editResponseSetDialogue");
        }

        private cancelResponseSetEdit() {
            this.$bvModal.hide("editResponseSetDialogue");
            utils.resetObject(this.responseSet);
            this.shouldDisableResponseSetIsLegacy = false;
        }

        private get editResponseSetTitle() {
            const module = this.allModules.find(m => m.id === this.responseSet.moduleID);
            const moduleDesc = module == null ? ("id=" + this.responseSet.moduleID) : module.description;
            if(this.responseSet.isNew) {
                return "Create new " + (this.responseSet.status === ResponseSetStatus.Legacy ? "Legacy Response" : "Response Set") + " for " + moduleDesc;
            }
            return "Edit Response Set for " + moduleDesc;
        }

        onDocumentUploaded(documentToAdd: ModelDocument) {
            this.responseSet.documents.push(documentToAdd);
            // no point adding ID as it won't have one until it's saved
        }

        onDocumentRemove(documentToRemove: ModelDocument) {
            this.responseSet.documents = this.responseSet.documents.filter(d => d.filename !== documentToRemove.filename);
            this.responseSet.documentIDs = this.responseSet.documentIDs.filter(id => id != documentToRemove.id);
        }

        private async saveResponseSet() {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.responseSet.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            this.isWaiting = true;
            const response: { newID: number } = await apiClient.post("api/SupplierResponse/SaveResponseSet", this.responseSet);
            if (this.responseSet.isNew) {
                this.responseSet.id = response.newID;
            }
            toastr.success("Saved");
            this.$bvModal.hide("editResponseSetDialogue");
            await this.load();
        }

        //
        // -- other actions
        //

        private goToSurvey(responseSet: ISupplierResponseSet) {
            this.$router.push("/survey/" + responseSet.id);
        }

        private async printReport(responseSet: ISupplierResponseSet) {
            const blob: Blob = await apiClient.get("api/report/survey?supplierResponseSetID=" + responseSet.id);
            utils.downloadBlob(document, blob, `survey-${utils.timeStampText()}.pdf`);

            //const url = apiClient.resolveUrl("api/report/survey?supplierResponseSetID=" + responseSet.id);
            //const win = window.open(url, "_blank");
            //if(win != null) win.focus();
        }


        private confirmDeleteResponseSet(responseSet: ISupplierResponseSet) {
            this.$bvModal.msgBoxConfirm("Are you sure you want to delete the response set created on " + utils.dateText(responseSet.created) + "?", {
                title: "Delete Response Set",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Leave it",
                hideHeaderClose: true,
                centered: true
            })
            .then((isConfirmed: boolean) => {
                if(!isConfirmed) return;
                this.deleteResponseSet(responseSet);
            });
        }

        private async deleteResponseSet(responseSet: ISupplierResponseSet) {
            const response: { message: string } = await apiClient.get(`api/SupplierResponse/deleteResponseSet?id=${responseSet.id}`);
            if(response.message === "ok") {
                toastr.info("Deleted");
                this.load();
            }
        }

        //
        // -- load, etc.
        //

        private async loadCqmsUsers() {
            const parameters = new UserSearchParameters();
            parameters.role = UserRole.Cqms;
            this.cqmsUserList = await apiClient.post("/api/user/searchLookups", parameters);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.cqmsUserOptions = [choose, ...this.cqmsUserList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadAllModules() {
            const params = new ModuleSearchParameters();
            params.buyerID = this.searchParameters.buyerID; // buyer user's buyer ID or zero if admin
            const responseData = await apiClient.post("api/module/search", params);
            this.allModules = responseData.list;
        }

        private async loadSchemeNames() {
            this.schemeNameList = await apiClient.get(`/api/lookup/search/${LookupGroup.SchemeName}`);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.schemeNameOptions = [choose, ...this.schemeNameList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadCdmCategories() {
            this.cdmCategoryList = await apiClient.get(`/api/lookup/search/${LookupGroup.CdmCategory}`);
        }

        private async loadSiteList() {
            const parms = new SiteSearchParameters();
            parms.supplierID = this.supplierID;
            this.siteList = await apiClient.post("/api/site/search", parms);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            const siteLookups = this.siteList
                .filter((s: ISite) => s.id > 0 && !utils.hasDateValue(s.deleted))
                .map<ILookupItem>((s: ISite) => { return {
                    id: s.id,
                    description: s.description,
                    isArchived: false
                };});
            this.siteOptions = [choose, ...siteLookups];
        }

        private async loadLegacyProgresses() {
            this.legacyProgressList = await apiClient.get(`/api/lookup/search/${LookupGroup.LegacyProgress}`);
            const choose = new LookupItem({ id: 0, description: "Complete", isArchived: false } as ILookupItem);
            this.legacyProgressOptions = [choose, ...this.legacyProgressList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async load() {
            this.isWaiting = true;
            const data = await apiClient.post("api/module/modulesWithResponses", this.searchParameters);
            this.modulesWithResponses = data.map((mwr: IModuleWithResponses) => new ModuleWithResponses(mwr));
            this.$emit("moduleCountChanged", this.modulesWithResponses.length); // update tab count badge in parent
            this.isWaiting = false;
        }

        private async loadBuyers() {
            this.buyerList = await apiClient.get(`/api/buyer/lookups?supplierID=${this.supplierID}&withusers=true`);
        }

        //
        // -- validation
        //

        private areDatesInRightOrder(): boolean {
            if(this.responseSet == null) { console.log("***  this.responseSet == null"); return true; }
            if(!utils.hasDateValue(this.responseSet.activeFrom)) { console.log("***  activeFrom not set ", this.responseSet.activeFrom); return true; }
            if(!utils.hasDateValue(this.responseSet.expires)) { console.log("***  expires not set", this.responseSet.expires); return true; }
            const x = utils.areDatesAscending(this.responseSet.activeFrom, this.responseSet.expires);
            console.log(`***  acending = ${x} from=${this.responseSet.activeFrom} to=${this.responseSet.expires}`)
            return utils.areDatesAscending(this.responseSet.activeFrom, this.responseSet.expires);
        }

        @Validations()
        validations() {
            const validations = {
                responseSet: {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                certificate: {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                chaseHistory: {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.responseSet.activeFrom = { 
                isInRightOrder: () => this.areDatesInRightOrder()
            };
            validations.responseSet.expires = { 
                isInRightOrder: () => this.areDatesInRightOrder() 
            };

            validations.certificate.certificateType = { hasValue: (value: number) => +value > 0 };

            validations.chaseHistory.createdDate = { hasValue: (value: Date) => utils.hasDateValue(value) };
            validations.chaseHistory.notes = { hasValue: (value: string) => !utils.isEmptyOrWhitespace(value) };

            return validations;
        }
    }
