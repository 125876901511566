import { ISurvey, Survey } from "@/model/Survey";
import { SurveyPage } from "@/model/SurveyPage";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyResponse } from "@/model/SurveyResponse";
import { SurveySection } from "@/model/SurveySection";
import { SupplierResponses } from "@/model/SupplierResponses";
import { mapData } from "@/stuff/DataMapping";
import utils from "@/stuff/Utils";

export interface IModuleExtract {
    moduleID: number;
    moduleDescription: string;
    responseSetID: number;
    activeFrom: Date;
    expires: Date;
    survey: ISurvey;
}

export class ModuleExtract implements IModuleExtract {

    constructor(data?: IModuleExtract) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IModuleExtract) {
        mapData(data, { 
            root: () => this,
            survey: () => new Survey(),
            surveyPages: () => new SurveyPage(),
            surveySections: () => new SurveySection(),
            surveyQuestions: () => new SurveyQuestion(),
            surveyResponses: () => new SurveyResponse(),
            supplierResponses: () => new SupplierResponses()
        });
    }
    
    moduleID: number = 0;
    moduleDescription: string = "";
    responseSetID: number = 0;
    activeFrom: Date = new Date(utils.emptyDateValue);
    expires: Date = new Date(utils.emptyDateValue);
    survey: Survey = new Survey();

}