
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import Checkboxes from "@/components/Survey/Checkboxes.vue";
    import RadioButtons from "@/components/Survey/RadioButtons.vue";
    import FreeText from "@/components/Survey/FreeText.vue";
    import SingleLineText from "@/components/Survey/SingleLineText.vue";
    import DatePicker from "@/components/Survey/DatePicker.vue";
    import Money from "@/components/Survey/Money.vue";
    import Integer from "@/components/Survey/Integer.vue";
    import Evidence from "@/components/Survey/Evidence.vue";
    import Matrix from "@/components/Survey/Matrix.vue";
    import NoResponse from "@/components/Survey/NoResponse.vue";
    import { ResponseSetStatus } from "@/model/Enums";
    import { IModuleExtract, ModuleExtract } from "@/model/ModuleExtract";
    import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
    import apiClient from "@/stuff/ApiClient";
    import { Authentication } from "@/stuff/Authentication";
    import { IUser } from "@/model/User";
    import store from "@/store/store";

    @Component({ 
        components: { Checkboxes, RadioButtons, FreeText, SingleLineText, DatePicker, Money, Integer, Evidence, Matrix, NoResponse } 
    })
    export default class ModuleContent extends Vue {

        async mounted() {
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.buyerID = this.isBuyerZone
                ? store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID
                : 0;
                
            this.load(this.supplierID);
        }
    
        //
        // -- properties
        //

        @Prop({ required: true }) 
        private supplierID!: number;

        private isBuyerZone: boolean = false;
        private buyerID: number = 0;

        private surveyQuestionOptions: SurveyQuestionOptions = new SurveyQuestionOptions(false, ResponseSetStatus.Accepted);

        private modules: Array<ModuleExtract> = [];

        // 
        // -- methods
        //

        private async load(id: number) {
            this.modules = [];
            const serverData: Array<IModuleExtract> = await apiClient.get(`/api/supplier/moduleExtracts?supplierID=${this.supplierID}&buyerID=${this.buyerID}`);
            this.modules.push(...serverData.map(m => new ModuleExtract(m)));
        }

    }
